import React, { useState } from 'react';
import dileep from "../Images/avatar1.jpg";
import monu from "../Images/avatar2.jpg";
import sonu from "../Images/avatar3.png";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from 'reactstrap';
import './Winners.css'; // Make sure your CSS is correctly handling the layout

// Helper function to chunk an array
function chunkArray(array, size) {
  const chunkedArr = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArr.push(array.slice(i, i + size));
  }
  return chunkedArr;
}

// Assuming your initial profiles array
let profiles = [
  { name: 'Dileep', image: dileep },
  { name: 'Sonu', image: monu },
  { name: 'Pappu', image: sonu },
  { name: 'Jay',  image: dileep },
  { name: 'Monu',  image: monu },
  { name: 'Appu',  image: sonu },

  // Add as many profiles as you need
];

// Chunk profiles into groups of three
const chunkedProfiles = chunkArray(profiles, 3);

// Convert these chunks into items for the carousel
const items = chunkedProfiles.map((chunk, index) => ({
  id: index,
  profiles: chunk,
}));

function Winners() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  // Move the rest of your component here...
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const prevIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(prevIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => (
    <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={item.id}
    >
      <div className="winner-slide">
        {item.profiles.map((profile, index) => (
          <div key={index} className="profile">
            <img src={profile.image} alt={profile.name} />
            <h3>{profile.name}</h3>
            <p>{profile.description}</p>
          </div>
        ))}
      </div>
    </CarouselItem>
  ));

  return (
    <div className="winners-container">
        <h1 className="text-center" style={{ fontWeight: 'bold' ,marginTop: '50px'  }}>WINNERS</h1>
        <br/>
        <p className="text-center" style={{ fontWeight: 'bold' }}>By using their skills and logic, these players topped 
        the leaderboard,  <br/>earned exciting Ludo real cash prizes and became our Ludo Champions!</p>
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
      </Carousel>
    </div>
  );
}

export default Winners;
