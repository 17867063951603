import React from 'react'
import './term.css';


function Term_Condition() {
  return (
    <>
   <div className="term-condition-container">
      <h1 className="about-us-heading text-center">Terms and Conditions</h1>

      <div className="term-content">

        <h3> THE TERMS AND CONDITIONS BY WHICH THIS SITE IS MANAGED. THE TERMS AND CONDITIONS OUTLINED IN THIS END USER AGREEMENT SHALL GOVERN YOUR USE OF THIS WEBSITE.</h3>

         <br/>
         <p>Kindly read this end client understanding cautiously. For a long time over 18 in particular.
This end client understanding oversees your utilization of www.Indiangames.com"Indian games", the portable game application, its site, its product, their related redesigns, fixes, and updates and related administrations (the "item") at present gave or which will be given by fabzen advancements private restricted.
Any individual ("client") using www.Indian games.com or the ludo domain application for profiting of the web based gaming administrations offered in that or taking part in the different competitions and games, being led on Indian games will be limited by these agreements, and any remaining guidelines, guidelines and terms of purpose alluded to thus or given by Indian games according to any ludo domain administrations.
By posting the new terms on the Indian games app or website, Indian games reserves the right to make any changes to these terms and conditions, rules, regulations, and terms of use referred to herein or provided by Indian games in relation to any Indian games services. When a user uses Indian games, they are agreeing to all of the terms and conditions outlined in this document or provided by Indian games in relation to any Indian games services, as they may be updated from time to time. Ludo domain may likewise tell the client of any change or adjustment in these agreements, rules, guidelines, and terms of purpose alluded to thus or given by Indian games, via sending an email to the client's enrolled email address or posting notices in the client accounts.
To go into this end client arrangement, you should be a grown-up of the lawful time of greater part in india and not from any of the accompanying states which have restricted a wide range of genuine cash games. That you are lawfully and monetarily answerable for all activities utilizing or getting to our product, including the activities of anybody you permit to admittance to your record. You insist that you have arrived at the lawful period of larger part, and comprehend and acknowledge this arrangement (counting its debate goal terms).
In the event that you are under the legitimate period of larger part, you are not qualified to enter this arrangement. Please immediately stop using this website.
Assuming you keep utilizing this site or the product and its administrations, by downloading or utilizing the product, you are consenting to ludo domain's help out and recognize that you have perused its protection strategy.
Certain words or expressions are characterized to have specific implications when utilized in this understanding. Section 24 provides definitions for those terms.</p>
<br/>
<h6> 1. Ownership fabzen technologies pvt ltd.</h6>
<p>(fabzen) owns all title, ownership, and intellectual property rights to the product, as well as any and all copies of the product. This includes, but is not limited to, any and all text, graphics, music, or sounds, messages or items of information, fictional characters, names, themes, objects, scenery, costumes, effects, slogans, places, characters, diagrams, concepts, choreographies, videos, audio-visual effects, domain names, and the item is safeguarded by public and global regulations, copyright settlements and shows and different regulations.</p>

<br/>
<h6>2. License grant:</h6>
<p> Indian games grants you a limited, personal, non-exclusive, non-transferable, non-sub licensable right and license to install and/or use the product and services for your personal, non-commercial use (the "license") until either you or Indian games terminate this. The gameplay of Indian games is similar to that of the original ludo game. The freedoms that www.Indiangames.comawards you under the permit are dependent upon the details of this arrangement, and you may possibly utilize the permit assuming you consent to every material term. Refreshes, updates, patches, and changes might be essential to have the option to keep on utilizing the item on specific equipment. The permit becomes viable on the date you acknowledge this arrangement. The versatile programming is authorized, not sold, to you; the permit doesn't concede you any title or possession in the product.
As relevant, certain pieces of the item might be utilizing outsider highlights, some of which are overseen by outsider suppliers for which extra terms and additionally expenses might apply. Kindly follow statement 12 for the equivalent.</p>
<br/>
<h6>3. Permit conditions</h6>
<p>3.1 You are not allowed to do any of the accompanying concerning the item or any of its parts:
ouse it economically or for a limited time reason;
oduplicate, imitate, show, perform, or in any case use it in a way that isn't explicitly approved in this understanding or its particular expressions;
ooffer your record or any other way move rewards to any outsider;
odecompile, disassemble, modify, adapt, translate, decompile, or disassemble them; • derive source code from them;
oeliminate, debilitate, evade, or alter any restrictive notification or name or security innovation remembered for them;
omake, create, appropriate, or utilize any unapproved programming projects to acquire a benefit in the web based game modes;
ouse them to encroach or disregard the privileges of any outsider, including yet not restricted to any licensed innovation, exposure, or protection freedoms;
ouse, commodity, or re-trade them disregarding any pertinent regulation or guideline; or on the other hand
oact in a way which is hindering to the pleasure in the product or the administrations by different clients as expected by ludo domain, in their only judgment, including yet not restricted to the accompanying - cheating, badgering, utilization of oppressive or hostile language, game surrender, game harm, spamming, social designing, or defrauding. </p>

<br/>
<h6>4. Utilization by those younger than 18 years</h6>
<p>In the event that we suspect that you are or get warning that you are at present under 18 years or were under 18 years (or underneath the period of greater part as specified in the laws of the locale appropriate to you) when you played any game through our administration, your record will be suspended (locked) to keep you from playing further or making any withdrawals from your record. We will then research the matter, including whether you have been playing the game as a specialist for, or generally for benefit, of an individual under 18 years (or beneath the time of greater part as specified in the laws of the purview material to you). If, after discovering that you: a) are presently; ( b) were under 18 years or beneath the larger part age which concerns you at the significant time; or on the other hand (c) have been at the command of an individual under 18 years or beneath the greater part age, wherein:
•All rewards right now or due to be credited to your record will be held;
•If you play through the service as an agent for a minor, all winnings must be paid to us immediately (we will seek to recover all costs associated with recovering such funds if you fail to comply with this requirement); as well as
•Any monies stored in your record which are not rewards will be gotten back to you or held until you turn 18 years of age at our only tact. We maintain whatever authority is needed to deduct instalment exchange charges from the sum to return, including upkeep expenses for stores to your record, which we might hold.
•In the occasion we suspect you are in break of the arrangements of this condition or are endeavouring to depend on them for a deceitful reason, we maintain all authority to make any move fundamental to explore the matter, including illuminating the pertinent policing. </p>

<br/>

<h6> 5. Clients' direct</h6>
<p> Clients consent to maintain these keeping specified guidelines, guidelines, and terms of purpose of the site. In the occasion, the client doesn't maintain these keeping guidelines, guidelines, and terms of purpose, ludo domain may, at its sole and outright carefulness, make a vital healing move, including however not restricted to: limiting, suspending, or ending any client's admittance to all or any piece of Indian game’s administrations;
•Deactivating or erasing a client's record and all connected data and documents on the record. When a user's game or winnings account is deactivated or deleted, any remaining funds will be transferred to the user's Indian games bank account, subject to any processing fees (if any) that may apply to such transfers as outlined in this document. Or then again
•Shunning granting any prize(s) to such client.
•Clients consent to give valid, exact, current, and complete data at the hour of enrolment and at any remaining times (as expected by ludo domain). In addition, users agree to update and maintain their registration data.
•A user cannot sign up for or use more than one Indian games account.
•Clients consent to guarantee that they can get all correspondence from ludo domain by checking messages from Indian games as a feature of their "protected shippers" list. If an email is delivered to a user's junk or spam folder and the user does not read it, Indian games will not be held responsible.
•Any secret phrase gave by ludo domain to a client may not be uncovered to any other individual. Clients may not utilize any other person's secret key. Clients are answerable for keeping up with the privacy of their records and passwords. Clients consent to advise Indian games of any unapproved utilization of their passwords or records or some other break of safety right away.
•Clients consent to leave/log out of their records toward the finish of every meeting. If the user doesn't follow these rules, Indian games won’t be held accountable for any loss or damage.
•Clients make a deal to avoid utilizing cheats, takes advantage of, computerization, programming, bots, hacks, or any unapproved outsider programming intended to change or slow down ludo domain's administrations as well as Indian games experience or aid such action.
•Clients make a deal to avoid duplicating, change, lease, rent, credit, sell, dole out, disperse, figure out, award a security interest in, or in any case move any right to the innovation or programming fundamental ludo domain or Indian game’s administrations.
•Clients make a deal to avoid disturbing, overburden, or help or aid the interruption or overburdening of (1) any pc or server used to offer or support ludo domain or the Indian game’s administrations (each a "server"); or (2) the use of the services provided by Indian games by any other user or person.
•Users agree not to initiate, assist, or become involved in any kind of attack, including but not limited to the distribution of a virus, a denial of service attack, or any other attempt to interfere with the use or enjoyment of Indian game’s services by any other person.
•Users are prohibited from circumventing, attempting to circumvent, encouraging, or assisting any other person to circumvent, any security, technology, device, or software that underlies or is part of Indian game’s services, or by any means other than the user interface provided by Indian games, from gaining unauthorized access to the user accounts, servers, or networks connected to Indian game’s services.
</p>
<br/>

<h3>USERS AGREE NOT TO USE INDIAN GAMES FOR ANY OF THE FOLLOWING, EXCLUDING THE FOREGOING:</h3>
<p>•To engage in any act or communication that is obscene, offensive, indecent, racial, communal, anti-national, objectionable, defamatory, or abusive;
•To bug, tail, undermine, or in any case disregard any lawful freedoms of others;
•To transmit content that is inappropriate, profane, defamatory, infringing, obscene, indecent, or unlawful through any means (collectively, "transmitting");
•To communicate documents that contain infections, adulterated records, or some other comparable programming or projects that might harm or unfavourably influence the activity of someone else's pc, Indian games, any product, equipment, or broadcast communications hardware;
•To publicize, participate in or execute any latest concurrence with the results of ludo domain;
•To send content in regards to administrations, items, reviews, challenges, fraudulent business models, spam, spontaneous publicizing or limited time materials, or networking letters;
•To promote, deal or sell any labour and products for any business reason professing to be from ludo domain without the express composed assent of Indian games;
•To download any document, recompile or dismantle or in any case influence our items that you know or sensibly ought to know can't be lawfully gotten in such way;
•To misrepresent or erase any creator attributions, lawful or other legitimate notification or restrictive assignments or marks of the beginning or the wellspring of programming or other material;
•To limit or repress some other client from utilizing and partaking in any open region inside our locales;
•To gather or store individual data about different clients;
•To disrupt or interfere with the servers, networks, or Indian games;
•To imitate any individual or element, including, yet not restricted to, a delegate of Indian games, or dishonestly state or in any case distort client's connection with an individual or substance;
•To fashion headers or control identifiers or different information to camouflage the beginning of any satisfied sent through Indian games or to control client's presence on ludo domain;
•To make any move that forces a preposterously or excessively huge burden on our framework;
•To take part in any criminal operations. You consent to utilize our release board administrations, talk regions, news gatherings, discussions, networks as well as message or correspondence offices (aggregately, the "gatherings") just to send and get messages and material that are legitimate and connected with that specific gathering.

Assuming a client picks a username that, in Indian game’s viewed as assessment is revolting, foul, oppressive or that could expose ludo domain to public stigmatization or hatred, ludo domain holds the right, without earlier notification to the client, to change such username and close the client or erase such username from Indian games, as well as deny such client admittance to ludo domain, or any mix of these choices.
A violation of these terms and conditions and the law is unauthorized access to Indian games. Clients make a deal to avoid getting to Indian games using any and all means other than through the connection point that is given by ludo domain to use in getting to Indian games. Our app may not be accessed, monitored, or copied in any way by automated means, including but not limited to agents, robots, scripts, or spiders.
Indian games might imply a component of monetary gamble and might propensity structure. If it's not too much trouble, play capably and despite the obvious danger ahead.
During interactivity, kindly know about your environmental elements and play securely. You concur that your utilization of the applications and play of the games is notwithstanding copious advice to the contrary, and it is your obligation to keep up with such wellbeing, responsibility, clinical life, and other insurance contracts as you consider sensibly significant for any wounds that you might bring about while utilizing the administrations. You also agree not to use the apps in a way that is against any law, rule, or regulation, or the rules and how to play, and you agree not to help or encourage anyone else to break any law, rule, or regulation. Without restricting the previous, you concur that related to your utilization of the applications, you won't cause profound trouble for others, won't embarrass others (openly etc.), won't attack, or compromise individuals, won't mimic some other individual or distort your alliance, title or authority, and won't in any case participate in action that might bring about injury, self-hurt, propensity framing designs, and additionally responsibility of any sort.
These are a few overall principles that can assist with making your playing experience more secure, and stay away from the gamble that you might enjoy unnecessary gaming and compulsion:
•Add cash to the ludo domain wallets just with cash that you can stand to spend.
•Please do not put any money you need for important or essential expenses like food, rent, bills, or tuition into your account wallets.
•Pre-plan your buys and the time you will spend playing on the sites and adhere as far as possible, no matter what your exhibition.
•Try not to play when you are vexed, tired, or discouraged. It is hard to use sound judgment when you are not feeling great.
•Try not to place off private and other significant undertakings to play. Make sure you finish all of your important tasks so you can play freely and calmly.
•Offset your ongoing interaction span with different exercises.
•Try not to acquire cash to make buys on the sites.
•Try not to involve all the monies in the game wallets in a solitary game or meeting.
•Take breaks and breaks consistently.
•Just partake in games that are proportionate with your ability level and monetary capacities. Try not to get enticed to take part in games with high section charges, regardless of winning reliably.

In addition, you release Indian games and its developers from all claims, demands, and damages (actual and consequential) of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with disputes you have with other app users or third parties while using the services.</p>
<br/>
<h6>6. Agree to screen</h6>
<p>At the point when you are utilizing the items, our server or a partnered outsider might screen your equipment arbitrary access memory (smash) for unapproved outsider projects/codes disallowed by segment 2&3 that connect with the items. That you give ludo domain, every one of the privileges and highlight to screen and record your ongoing interaction at any stage they could consider fit. If we or our associates recognizes any such unapproved outsider program or limited utilize spread out in condition 2& 3, data might be imparted back to Indian games, including the name of your record, insights regarding the unapproved exercises distinguished, and the time and date that the unapproved outsider program was identified, alongside the equipment particulars and execution qualities of your equipment, regardless of extra notification to you. What's more, further you give ludo domain full privileges to recognize your in-application exercises according to RBI-based AML strategy. Ludo domain is permitted to answer to the concerned specialists assuming that your in-game exercises conflict with the laws of India.
However, please be aware that Indian games is not accountable for and does not endorse the opinions, suggestions, and/or advice that you display or send in the products, including chats that take place within the game. Such correspondences are the sole liability of the client being referred to.</p>
<br/>
<h6>7. Stage expense data</h6>
Indian games charges a stage expense from the user(s), for making accessible their e-stages where the talent based contests and different items are accessible and offering the support to the clients to partake in those talent based contests or consume the substance as advertised.
The stage charge for every one of the items/games is a decent rate % of the assigned sum and under very excellent cases might change as needs be. This can be effectively derived from the assigned sum and the all-out prize advertised. Clients can request explanation about the stage charge by reaching us at support@Indiangames.com.
<br/>
<h6>8. Dropping arrangement</h6>
Indian game’s centre is 100 percent consumer loyalty and security. In case of a blunder in the exchange, improper exchanges, mixed up personality, or whatever other issues that can be confirmed and demonstrated, we will discount the cash being referred to the client, gave the reasons are certifiable and demonstrated after examination. If it's not too much trouble, read the rules on each association, challenge, competition, or administration we give prior to paying to it, it gives every one of the insights regarding the administrations we give and the challenge sections clients buy. In the event of disappointment with Indian games's administrations, clients have the freedom to drop their buys and demand a discount from us. To find out about our discount and abrogation strategy, visit discount and retraction on our site or press here.
You might stop utilizing our gaming administrations and drop your record by messaging us at support@Indiangames.com with an unmistakable clarification of your goal and term from 48 hours to a half year. Subsequent to assessing your case, at our circumspection, www.Indiangames.comwill discount your record balance less any rewards, motivations or special offers credited to your record inside 10 working days and end your record. If it's not too much trouble, note, that any record moves you initiate in the wake of messaging your solicitation for ending your record, including, however not restricted to, playing cash games, competitions, saving and pulling out cash, will be considered as activities taken exclusively by you and Indian games will not be answerable for your activities.
Please click this link to learn more about our cancellation and refund policy.
Please use the contact us section of the apps or the website to get in touch with us about cancelling transactions that have already been completed.
<br/>
<h6>9. End</h6>
Without restricting some other privileges of fabzen, this arrangement will end naturally without notice assuming that you neglect to conform to any of its mandatory agreements. You may likewise end this understanding by erasing all duplicates of the product. The license will end on its own accord, you won't be able to use any of your rights that were granted to you by it, and you'll have to throw away all copies of the software you have. Besides as explicitly expressed in any case thus or to the degree legally necessary. Indian games might end your record in consistence with any leader or administrative body. Ludo domain is under no impulse to legitimize such activity to any client.
Besides as explicitly expressed in any case thus or to the degree legally necessary, or our standard practice, on account of any material break of this end client arrangement, all instalments and charges are non-refundable under all conditions, whether or not this understanding has been ended.
<br/>
<h6>10. Consistence with protection strategy</h6>
To find out about how your information is taken care of by ludo domain, kindly visit protection strategy on our site or press here. We really bend over backward, including occasional audits, to guarantee that the individual data you furnish is utilized in congruity with the protection strategy. Assuming you have any worries about our adherence to this protection strategy or how your own data is utilized, benevolently keep in touch with us support@Indiangames.com. We will get in touch with you and, if necessary, work with the appropriate regulatory authorities to address your concerns effectively.
<br/>
<h6>11. Information maintenance</h6>
Indian games maintains whatever authority is needed to hold player data for an endless timeframe to uphold the end client permit understanding ludo domain, forestall extortion, resolve a case, or follow some other demonstrations allowed by regulation. Indian games saves such privileges, whether a record is ended by ludo domain or in line with the player, information is held for the lifetime of the game. On the off chance that a client believes Indian games should erase every one of their information from their reinforcement, they should email us on support@Indiangames.com to successfully eliminate every one of the information.
<br/>
<h6>12. Updates and fixes</h6>
Fabzen might give fixes, updates, or moves up to the Indian game’s versatile applications that should be introduced for you to keep on utilizing the product or administrations. Fabzen might refresh the product from a distance without informing you, and you thus agree to fabzen applying patches, updates, and overhauls. Fabzen might alter, suspend, stop, substitute, supplant, or limit your admittance to any part of the product or administrations whenever. You recognize that your utilization of the product or administrations doesn't present on you any interest, money related etc., in any viewpoint or element of the product or administrations, including however not restricted to any in-game prizes, accomplishments, or character levels. You likewise recognize that any person information, game advancement, game customization or different information connected with your utilization of the product or administrations might fail to be accessible to you whenever without notice from fabzen, including, without limit, after a fix, update, or redesign is applied by fabzen. Fabzen has no upkeep or backing commitments concerning the product or administrations.
<br/>
<h6>13. Criticism</h6>

On the off chance that you give fabzen any criticism, you thus award fabzen a non-selective, completely paid, eminence free, permanent, ceaseless, adaptable, sub licensable permit to utilize, duplicate, disseminate, change, adjust, plan subordinate works in light of, openly perform, openly show, make, have made, use, offer, propose to sell, import, and in any case exploit that criticism for any reasons, for all current and future techniques and types of double-dealing in any country. On the off chance that any such freedoms may not be authorized under relevant regulation (like moral and other individual privileges), you thusly forgo and make a deal to avoid stating every single such right. You get it and concur that fabzen isn't expected to utilize any input that you give. You agree that fabzen is not required to credit or compensate you if it uses your feedback. You address and warrant that you have adequate freedoms in any criticism that you give to fabzen to allow fabzen and its impacted gatherings the privileges depicted previously. This incorporates yet isn't restricted to licensed innovation privileges and other exclusive or individual freedoms.
<br/>
<h6>14. Outsider destinations, administrations and items</h6>
Ludo domain might contain connections to other web destinations possessed and worked by outsiders. Clients' utilization of every one of those destinations is dependent upon the circumstances, if any, posted by the locales. Apart from Indian games, Indian games has no control over any other websites, and it cannot be held accountable for the content of any websites owned by third parties. Ludo domain's consideration of outsider substance or connections to outsider web locales isn't a support by Indian games of such outsider web destinations. Ludo domain is utilizing apps, an outsider help to track and improve the showcasing and promotion lobbies for Indian games and their clients.
Indian games processes clients' correspondence, exchanges, or related exercises with an outsider to be specific cash free, who are our incorporated instalment escape suppliers and monetary specialist co-ops. In the event that we change our outsider specialist organizations, we will refresh a similar here for you, here. Clients' correspondence, exchanges, and use of the administrations of such outsider will be dependent upon the agreements, arrangements and other assistance terms embraced/carried out by such outsider, and the client will be exclusively answerable for inspecting a similar preceding executing or profiting of the administrations of such outsider. Client concurs that ludo domain won't be mindful or obligated for any misfortune or harm of any kind caused because of any such exchanges with outsiders. Any inquiries, grievances, or cases connected with any outsider assistance ought to be coordinated to the suitable seller.
<br/>  <h6>15. Disclaimers and limitations of liability the services and software are provided </h6>"as is," "with all faults," and without any kind of warranty. Fabzen, its associates, and its and their licensors and specialist co-ops (on the whole, the "fabzen gatherings") disavow all portrayals, guarantees and conditions (whether express or suggested) as for the product and administrations, including, without restriction, in regards to non-encroachment, merchantability, or wellness or appropriateness for any reason (whether fabzen knows or has motivation to know about any such reason), whether emerging under regulation, by reason of custom or use in the exchange, or by course of managing. Without restricting the over-simplification of the previous, the fabzen gatherings make no guarantee that (1) the product or administrations will work appropriately consistently, (2) the activity of the product or administrations will be continuous or liberated from bugs, mistakes, or malware, (for example, infections), or (3) any imperfections in the product or administrations can or will be revised. The provisions of this paragraph shall apply to the fullest extent permitted by law.

By entering the Indian games applications/site and getting to the ludo domain's administrations gave in that, clients thusly discharge from and consent to repay Indian games, or potentially any of its chiefs, workers, accomplices, partners and licensors, from and against all responsibility, cost, misfortune or cost emerging out their admittance to the Indian games's administrations including (however not restricted to) individual injury and harm to property and whether immediate, circuitous, considerable, predictable, because of some careless demonstration or oversight on their part, etc.
Ludo domain acknowledges no obligation, whether together or severally, for any blunders or oversights, whether for itself or outsiders.
Clients will be exclusively liable for any results which might emerge because of their admittance to the ludo domain's administrations by directing an unlawful demonstration or due to non-similarity with these agreements and different standards and guidelines comparable to Indian game’s administrations, including the arrangement of inaccurate location or other individual subtleties.
<br/>
<h6>Domain or his/her support in any contest(s)</h6>
Regarding ludo domain permitting clients to get to the Indian game’s administrations facilitated on ludo domain, to the most extreme degree allowed by regulation, the clients postpone and deliver every single right or guarantee, all activities, reasons for activities (present or future) every one of them has or may have against Indian games, its individual specialists, chiefs, officials, business partners, bunch organizations, backers, workers, or agents for all and any wounds, mishaps, or accidents (whether known or obscure) or (whether expected or unexpected) emerging out of the arrangement of Indian game’s administrations or connected with the challenges or the awards of the challenges.
<br/>
<h6>16. Reimbursement</h6>
You consent to reimburse, pay the protection expenses of, and hold innocuous Indian games, the fabzen gatherings and their separate workers, officials, chiefs, specialists, project workers, and different delegates from all cases, requests, activities, misfortunes, liabilities, and costs (counting lawyers' charges, expenses, and master observers' expenses) that emerge from or regarding (a) that's what any case, if valid, would comprise a break by you of this understanding or carelessness by you, (b) any demonstration or oversight by you in utilizing the product or administrations, or (c) any case of encroachment or infringement of any outsider protected innovation freedoms emerging from fabzen's utilization of your input. You consent to repay the fabzen gatherings on interest for any safeguard costs caused by the fabzen gatherings and any instalments made or misfortune endured by the fabzen gatherings, whether in a court judgment or settlement, in light of any matter covered by segments 3 and 4.
In the event that you are restricted by regulation from entering the reimbursement commitment above, you expect, to the degree allowed by regulation, all responsibility for all cases, requests, activities, misfortunes, liabilities, and costs (counting lawyers' expenses, expenses, and master observers' charges) that are the expressed topic of the repayment commitment above.
Unless you have agreed to software specific terms for that software or service that include an indemnity of the developer, the developer of a software or service licensed to you under this agreement—whether fabzen, its affiliate, or a third party—is referred to as "fabzen parties" in the preceding indemnity provisions.
<br/>
<h6>17. Winnings withdrawal</h6>
Grants for all competitions and challenges will be pre-pronounced at the hour of enrolment in the particular guidelines pertinent to such competitions and challenges. Grants might be as money or special gifts.
Clients are qualified to pull out just game rewards or money rewards from his/her record to his/her particular bank/wallet account whenever it is approved and KYC (know your client) confirmed. If you are withdrawing more than rs, KYC is required. 10,000/ - from your triumphant wallet.
Kindly guarantee your financial balance/UPI id is dynamic, bombing which, we will not be able to handle the withdrawal; also,
<br/>
<h6>KYC conditions</h6>
Www.Indiangames.com approves the client account upon starting withdrawal through KYC confirmation. Indian games likewise maintains whatever authority is needed to do the KYC methodology when a client arrives at a total lifetime withdrawal of rs. 10,000. In the event of withdrawal through a financial balance or UPI, if it's not too much trouble, guarantee that the name on your KYC report matches/is equivalent to the name of the record holder submitted for pulling out rewards. In the occasion the name on the KYC record doesn't match the record holder's name, the equivalent will be viewed as invalid. In such an occasion, you might retry adding your financial balance subtleties/UPI id to guarantee the name matches the name on your KYC archive.
KYC check is required as evidence of character for making withdrawals from your record to your bank/wallet account. KYC confirmation might be finished through the stage by giving specific subtleties: ( i) photo of a substantial government id (adhar card, dish card, and so forth.); furthermore (ii) other client subtleties.
<br/>
<h5>Rule for the "KYC cycle" confirmation of id:</h5>
• The address and signature are present • the resident state is not restricted: sikkim, andhra pradesh, and telangana; assam; odisha; tamil nadu; nagaland; sikkim; andhra pradesh; or telangana; full name matches user's name; document will not expire in the next three months; owner is over 18 years old.
If you choose to complete your KYC with your aadhar card or number, you agree to the following: i) comprehend and concur that your aadhar has been submitted exclusively for the consummation of your KYC check; (ii) you approve us to briefly get to your aadhar card and you comprehend that we won't store your aadhar number except if as endorsed by regulation. (iii) Contest entries are not acknowledged by Indian games. Within the platform's account, the entries are accounted for. It is the obligation of the client to guarantee that the passages are precise and the rewards are properly credited to his/her record. (iv)Any expense/demands/obligation and so forth. as pertinent on material awards, will be paid by the champ of the game.
Any special code gave to you to buy or reclaim for utilizing the product offices as well as administrations might be dependent upon extra agreements related with the advancement, proposition, or coupon. At the point when you give an instalment card, special code, or other instalment techniques to ludo domain to make a buy, you address to Indian games that you are the approved client of the instalment strategy, and you approve ludo domain to charge your instalment technique for the buy sum, including pertinent derivations or expenses (if any). You are answerable for all exchanges made through your Indian games accounts.
The choice of ludo domain as for the granting of prizes will be conclusive, restricting, and non-contestable. Assuming they are not residents of any of the Indian states listed below—assam, odisha, andhra pradesh, tamil nadu, nagaland, sikkim, or telangana—players in the paid formats of the contest(s) warrant this. Indian games will disqualify a participant and forfeit any prizes won by that user if it is determined that the participant is a resident of any of the aforementioned states while participating in the paid formats of the contest(s). Further ludo domain may, at its sole and outright prudence, suspend or end such member's record with Indian games applying appropriate supports (if necessary).
Any sum staying unused in the client's down record or rewards record on the date of deactivation or cancellation will be repaid to the client by a web-based move to the client's financial balance on record with Indian games, dependent upon the handling charge (if any) appropriate on such exchanges as set out thus. Assuming that it is found that a member playing the paid organizations of the contest(s) is younger than eighteen (18), ludo domain will be entitled, at its sole and outright tact, to preclude such member and relinquish his/her award.
<br/>
<h6>18. Bonus conditions in accordance with the terms of the Indian games wallet</h6>, any bonuses that a user accumulates in the bonus wallet—including but not limited to sign-up bonuses and coupon cashbacks—will be deducted and returned to Indian games after seven days.
That's what the client grasps on the off chance that the client doesn't use the reward wallet sum in something like 7 days of being achieved, the client relinquishes every one of their privileges over the reward wallet balance cash sum and the reward balance will be consumed by Indian games.
<br/>
|<h6>19. Charges payable</h6>
All prizes will be dependent upon derivation of expense ("TDS") according to the annual assessment act 1961. As of April 1, 2023, the TDS rate endorsed by the public authority of India regarding any award cash sum is 30% of the net winning sum. In the event of any corrections by the public authority of India to the previously mentioned rate from here on out, TDS will be deducted by ludo domain as per the then-current recommended TDS rate and edge. Any additional taxes, such as income tax, gift tax, and so on that are owed by the winners are their own. In regard of the award cash continuously.
According to the GST (alteration) act, 2023, and GST (revision) act, 2023, all stores made by the client will be exposed to 28% GST (labour and products expense). As of October 1, 2023, the GST rate endorsed by the public authority of India as for any stores made by the clients is 28% of that saved sum. Appropriately, the GST piece of the client's saved sum will be paid in the interest of the client by ludo domain to the public authority. The client comprehends that the sum stored by the client won't be considered as comprehensive of GST. The client will be at risk to pay for some other charges/obligations/demands.
<br/>
<h6>20. Client care and grievances</h6>
Assuming you have any worries or questions in regards to these terms, you ought to contact our client support office through the connections on the site and utilize your enrolled email address in all correspondence with us.
Debates should be held up inside three (3) days from the date of the game being referred to which was been played by the complainant client. No cases will be respected after this period. The client is exclusively liable for their record exchanges. In case of a question emerging among you and us, our client care division will endeavour to arrive at a concurred arrangement. Should our client care office not be able to arrive at a concurred arrangement with you, the matter will be raised to our legitimate group.
<br/>
<h6>21. Administering regulation and purview</h6>
You concur that this understanding will be considered to have been made and executed in the Bangalore, India and any case will be settled thus. You and fabzen consent to determine debates between us in individual assertion (not in court). We accept the elective debate goal cycle of mediation will determine any question reasonably and more rapidly and productively than formal court case. We have put this front and centre (and in covers) since it is significant:
To go into this permit understanding, you should be a grown-up of the lawful period of larger part in your nation/territory of home. You are lawfully and monetarily liable for all activities utilizing or getting to our product, including the activities of anybody you permit to admittance to your record. You insist that you have arrived at the lawful period of larger part, and comprehend and acknowledge this arrangement (counting its debate goal terms). Assuming you are under the lawful time of greater part, you are not qualified to enter this arrangement. Any claim made by a user who is under the legal age to use the service will be deemed null and void.
Assuming you utilize outsider programming or administrations, the security strategy of the appropriate licensor or distributer may likewise administer your utilization of that product or administrations. By downloading or utilizing the product, you likewise consent to fabzen's help out and recognize that you have perused fabzen's protection strategy.
Any regulation or guideline which gives that the language of an agreement will be understood against the drafter won't matter to this understanding.
<br/>
<h6>22. Individual</h6>
 Binding arbitration please carefully read this section. It influences your freedoms, remembering your entitlement to document a claim for court.
Most issues can be settled rapidly and agreeably by reaching fabzen client care at support@Indiangames.com. However, we are aware that disagreements may not always be resolved internally. This part makes sense of how you and fabzen consent to determine those questions, including (where material) restricting, individual discretion.
Assertion is an elective question goal (adr) strategy that permits us to determine issues without the convention of going to court. Any question among you and fabzen is submitted to an impartial referee (not an adjudicator or jury) for fair and quick goal. Mediation is more effective for both, you and fabzen.
<br/>
<h6>Casual goal</h6>
Assuming you have an issue that our client care can't determine, preceding beginning mediation you and fabzen consent to endeavour to determine the debate casually to assist with getting us to a goal and control costs for the two players. You and fabzen consent to put forth a pure intentions attempt to haggle any debate between us for no less than 30 days ("casual goal"). Those casual discussions will begin the day you or fabzen get a composed notification of a debate event as per this understanding.
You will send your notification of debate to fabzen advancements private restricted. Lawful division, Attn: plot no. Notice of dispute 77, fifth floor, jbr Tech Park, sixth road, epip zone, Whitefield, Bengaluru - 560066. Include your address, your name, the account name you use to use the software, how to contact you, the issue, and what you want fabzen to do. Our notice of dispute will be sent to your registered email address and any billing address you have provided if fabzen has a dispute with you.
In the event that the question isn't settled inside by casual goal, you or fabzen might begin assertion as per this arrangement.
This section's arbitration proceedings will only be conducted on an individual basis.
You and fabzen concur that debates will be settled by restricting, individual intervention directed by the discretion and mollification act, 1996 as conveyed by this understanding.
This implies that you and fabzen consent to a question goal process where we present any debate to a nonpartisan mediator (a commonly concurred referee) that settles on the last choice to determine the question. This will empower us to utilize experienced experts to mediate debates, which assists you and fabzen with settling any questions decently, yet more rapidly and effectively than going to court. The mediator might grant similar solutions for you separately as a court could, however just to the degree expected to fulfill your singular case.
The referee's choice is conclusive, with the exception of a restricted survey by courts under the intervention and mollification act, 1996, and can be upheld like some other court request or judgment. Fabzen and you both agree that the arbitrator, not a court, will decide whether a dispute is subject to arbitration under this agreement.
Nothing in this provision will prevent www.Indiangames.comfrom illuminating the leader specialists seeing any resistance according to statements (3) and (4) or potentially in case of any monetary or digital wrongdoing according to it act, 2000 and the Indian corrective code, when we might consider fit and significant.
<br/>
<h6>23. Severability:</h6> If any part of this binding individual arbitration agreement is found to be illegal, invalid, or unenforceable, you and fabzen agree that that part will be severed, but the rest of the agreement will still be in effect and interpreted as if it had no severed provision at all. The only exception is that you and fabzen agree that the class action waiver will not be severable if it is found to be invalid, unenforceable, or illegal; this whole restricting individual discretion segment will be void and unenforceable and any debate will be settled in court subject to the scene and selection of statements determined in this arrangement. By no means will intervention be led on a class premise without fabzen's express assent.
<h6>24. Non-task</h6>
You can't appoint, move, charge, or sub-contract all or any of your privileges or commitments under this arrangement, and any endeavour without that assent will be invalid and void. Assuming limitations on the exchange of the product in this arrangement are not enforceable under the law of your country, then this understanding will be restricting on any transferee of the product. Any and all of fabzen's rights and responsibilities under this agreement may be assigned, transferred, charged, or subcontracted at any time.
Caution: a client is thusly suspended, under this end client arrangement, to sell, move or relegate his client record to some other person. Be educated, that your client information is explicitly allocated to your utilization and isn't adaptable to any outsider. On the off chance that we track down motivations to accept that a client has been resistant to this statement, Indian games may, at its own watchfulness, and with practically no further notification, drop, stop, or erase the record which could have been doled out to another client. On that event, our discount strategy will not follow.
<br/>
<h6>25. Definitions the following capitalized terms have the following meanings in this agreement:</h6>
"fabzen" signifies, fabzen innovations private restricted, inc., a confidential restricted organization, its primary business office is at plot no. 77, fifth floor, jbr tech park, sixth rd, epip zone, whitefield, bengaluru - 560066
"Input" signifies any criticism, or ideas that you give to fabzen in regards to the product, administrations or other fabzen items and administrations.
"Items" signifies the portable uses of Indian games created by fabzen advancements. Any notice of items as a subject or anything relating to the utilization of an item, as being alluded to will mean Indian games.
"Programming explicit terms" signifies extra agreements well defined for a specific programming or administration.
The term "services" refers to any services provided to you by the software, such as services to purchase games, download games, or use other software or services.
"Programming" signifies the genuine cash application games gave by "Indian games" which is the topic of the end client understanding. Every one of the terms and statements set down before were regarding ludo domain. The expression "programming" likewise incorporates any patches, updates, and moves up to such programming, and all connected substance and documentation gave or for the product, also including yet not restricted to all product code, titles, subjects, objects, characters, names, discourse, expressions, areas, stories, craftsmanship, liveliness, ideas, sounds, general media impacts, techniques for activity, and melodic arrangements that are connected with such programming, and any duplicates of any of the previous.
"classified data" signifies (a) the items and (b) the private or exclusive data conveyed under this arrangement with the exception of data which (i) was known to the beneficiary at the hour of exposure; ( ii) is or opens up without shortcoming of the beneficiary; what's more (iii) is legally gotten by the beneficiary from an outsider without classification limitations.
<br/>
<h6>26. Random</h6>
• This understanding, along with the terms and conditions, security strategy, and any product explicit terms or other extra terms you might have consented to with fabzen, comprises the whole arrangement among you and fabzen connecting with the topic covered by this arrangement. Any remaining interchanges, proposition, and portrayals as for the topic covered by this arrangement are barred.
•The first of this arrangement is in english; any translations are provided solely for reference. You postpone any right you might have under the law of your country to have this arrangement composed or interpreted in the language of some other country.
•This understanding portrays specific legitimate freedoms. You might have different privileges under the laws of your ward. This arrangement doesn't change your privileges under the laws of your ward in the event that the laws of your purview don't allow it to do as such. Limits and rejections of guarantees and cures in this understanding may not concern you in light of the fact that your locale may not permit them in your situation. If certain arrangements of this arrangement are held by a court or council of skillful purview to be unenforceable, those arrangements will be upheld just to the uttermost degree conceivable under relevant regulation and the leftover terms of this arrangement will stay in full power and impact.
•Any demonstration by fabzen to exercise, or disappointment or postpone in exercise of, any of its privileges under this understanding, at regulation or in value won't be considered a waiver of those or some other freedoms or cures accessible in agreement, at regulation or in value.
•Except as specifically stated, you acknowledge and agree that none of the parties to this agreement are entitled to any rights or remedies.
•Our commitments are likely to existing regulations and legitimate cycles, and fabzen might consent to policing administrative solicitations or prerequisites regardless of any opposite term in this arrangement.
<br/>
<h6>
27. Corrections of this arrangements</h6>
Fabzen might give a corrected arrangement out of the blue at its carefulness by posting the revised settlement on its site or by giving you advanced admittance to the altered understanding through the product or different means. On the off chance that any revision to this understanding isn't satisfactory to you, you might end this arrangement as per area 8 or 9 preceding such corrected arrangement becomes viable, whereupon you should quit utilizing the product. Independently by utilizing the product after the altered arrangement becomes viable etc., this will show your acknowledgment of the changed understanding, and that you are consenting to be limited by the conditions of the corrected understanding.

</div>

    </div>
  </>
  
  )
}

export default Term_Condition