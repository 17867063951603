import React from 'react';
import './App.css';
import CustomNavbar from './components/CustomNavbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomFooter from './components/CustomFooter';
import Winners from './components/Winners';
// import Testimonials from './components/Testimonials';
import AboutUs from './components/AboutUs';
import Background from './components/background';
import TermPage from './components/term';
import PrivacyPolicy  from './components/privacyPolicy';
import ContactUs from './components/contactUs'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import Routes instead of Switch

function App() {
  return (
    <Router>
      <>
      <CustomNavbar />

        <Routes>
          <Route path="/" element={
            <>
              <Background />
              <Winners />
              {/* <Testimonials /> */}
              <AboutUs />
            </>
          } exact />

          <Route path="/term" element={<TermPage />} /> 
          <Route path="/contactUs" element={<ContactUs />} /> 
          <Route path="/privacyPolicy" element={<PrivacyPolicy  />} /> 
          
        </Routes>

        <CustomFooter />
      </>
    </Router>
  );
}

export default App;
