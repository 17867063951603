import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { Col, Container, Row } from 'reactstrap';
import download from "../Images/donload3.jpg";
import './CustomFooter.css';

import {
  NavbarBrand,
} from 'reactstrap';

function openWebsite() {
  window.open("https://indiangames.live/", "_blank");
}

function CustomFooter() {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md="4" className="footer-col">
            <h5>QUICK LINKS</h5>
            <p>About Us</p>
          </Col>
          <Col md="4" className="footer-col">
            <h5>POLICIES</h5>
            <p>
              <Link to="/term" className="nav-link">Terms and Conditions</Link>
            </p>
            <p>
              <Link to="/privacyPolicy" className="nav-link">Privacy Policy</Link>
            </p>
            <p>
              <Link to="/contactUs" className="nav-link">Contact Us</Link>
            </p>
          </Col>
          <Col md="4" className="footer-col">
            <h5>CONTACT US</h5>
            <b>Website: </b><a href="https://indiangames.live/" onClick={openWebsite}>indiangames.live</a>
            <NavbarBrand href="https://indiangames.live/ludoindian.apk">
              <div style={{ backgroundColor: 'black', padding: '10px', display: 'inline-block', borderRadius: '20px' }}>
                <img
                  src={download}
                  style={{ maxWidth: '100px', height: 'auto' }} 
                  alt="Logo"
                  className="navbar-logo"
                />
              </div>
            </NavbarBrand>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default CustomFooter;
