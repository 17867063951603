import React, { useState } from 'react';
import logo from "../Images/logo1.jpg"
import googlePlay from "../Images/googlePlay1.png"
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  // UncontrolledDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
} from 'reactstrap';
import './CustomNavbar.css'; 

function CustomNavbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar color="primary" light expand="md" className="custom-navbar">
      <NavbarBrand href="/">
  <img
    src={logo}
    style={{ maxWidth: '100px', height: 'auto', borderRadius: '20px' }}
    alt="Logo"
    className="navbar-logo"
  />
</NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <div className="navbar-center">
            <Nav className="m-auto" navbar>
              <NavItem>
                <NavLink href="#">HOME</NavLink>
              </NavItem>
              {/* <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  LEARN THE GAME
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>Ludo Rules</DropdownItem>
                  <DropdownItem>Ludo Demo</DropdownItem>
                  <DropdownItem>Ludo Tips</DropdownItem>
                  <DropdownItem>Ludo Download</DropdownItem>
                  <DropdownItem>How To Play</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}
              <NavItem>
                <NavLink href="#">WINNERS</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#">ABOUT US</NavLink>
              </NavItem>
            </Nav>
          </div>
        </Collapse>
        <NavbarBrand href="https://indiangames.live/ludoindian.apk">
        <img
  src={googlePlay}
  style={{ maxWidth: '200px', height: 'auto', borderRadius: '20px' }} 
  alt="Logo"
  className="navbar-logo"
/>

</NavbarBrand>
      </Navbar>
    </div>
  );
}

export default CustomNavbar;
