// AboutUs.js

import React from 'react';
import './AboutUs.css'; 
import about from "../Images/about.png";

function AboutUs() {
  return (
    <div className="about-us-container">
      <h1 className="about-us-heading text-center">About Us</h1>
      <div className="about-us-content">

        <div className="about-us-image">
          <img src={about} alt="About Us" />
        </div>

        <div className="about-us-text">
          <p>
          Indian game Empire is India's fastest-growing Indian game online.
            This real cash Indian game provides you with the opportunity to play
            the game of your childhood with the added twist of earning with just 
            your skills. you can now play Indian game  online  from anywhere, 
            anytime in India.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
