import React from 'react';
import './background.css'; // Make sure to import the CSS file

function Background() {
  return (
    <div className="background">
      {/* Your component content goes here */}
    </div>
  );
}

export default Background;
