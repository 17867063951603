import React from 'react';
import './term.css';


function ContactUs() {
  return (
    <>
   <div className="term-condition-container">
      <h1 className="about-us-heading text-center">Contact Us</h1>

      <div className="term-content">

<h6>Last updated on 13-03-2024 17:02:02
You may contact us using the information below</h6>

<b>Merchant Legal entity name: </b>   MUTTEPPA ARABHANVI
<br/>
<b>Registered Address: </b>    Tukkanatti, Kadamagola Tvota, Belgavi, Karnataka,
PIN: 591224
<br/>
<b>Operational Address: </b>
   Tukkanatti, Kadamagola Tvota, Belgavi, Karnataka, PIN: 591224
 <br/>
<b>Telephone No: </b>  7353741149
<br/>
<b>E-Mail ID: </b> mutturajarabhanvi4@gmail.com

</div>

    </div>
  </>
  
  )
}

export default ContactUs